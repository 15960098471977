import type { NextPage, GetServerSideProps, InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import StartPage from 'home/components/StartPage'
import { serverSideTranslations } from 'shared/translations/serverSide'
import { useTranslation } from 'shared/translations'
import { formatPageMetaTitle } from 'shared/utils'

const Home: NextPage = (_props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { t } = useTranslation('home')
  return (
    <>
      <Head>
        <title>
          {formatPageMetaTitle({
            pageTitle: t('meta.title')
          })}
        </title>
      </Head>
      <StartPage />
    </>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps<Record<string, unknown>> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', [
        'common',
        'user',
        'workspace',
        'home',
        'dataSource',
        'recommendation'
      ]))
    }
  }
}
